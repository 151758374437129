// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-calculator-calculator-script-mdx": () => import("./../../src/calculator/calculator-script.mdx" /* webpackChunkName: "component---src-calculator-calculator-script-mdx" */),
  "component---src-converter-converter-mdx": () => import("./../../src/converter/converter.mdx" /* webpackChunkName: "component---src-converter-converter-mdx" */),
  "component---src-calculator-calculator-standard-mdx": () => import("./../../src/calculator/calculator-standard.mdx" /* webpackChunkName: "component---src-calculator-calculator-standard-mdx" */),
  "component---src-graph-maker-graph-maker-pie-mdx": () => import("./../../src/graph-maker/graph-maker-pie.mdx" /* webpackChunkName: "component---src-graph-maker-graph-maker-pie-mdx" */),
  "component---src-utilities-utilities-mdx": () => import("./../../src/utilities/utilities.mdx" /* webpackChunkName: "component---src-utilities-utilities-mdx" */),
  "component---src-graph-maker-graph-maker-column-mdx": () => import("./../../src/graph-maker/graph-maker-column.mdx" /* webpackChunkName: "component---src-graph-maker-graph-maker-column-mdx" */),
  "component---src-introduction-introduction-mdx": () => import("./../../src/introduction/introduction.mdx" /* webpackChunkName: "component---src-introduction-introduction-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

