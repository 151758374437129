module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\node_modules\\gatsby-theme-docz\\src\\base\\Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["简介","计算器","转换器","制图器","实用工具"],"mdPlugins":[],"hastPlugins":[],"ignore":[],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":false,"o":false,"open":false,"open-browser":false,"root":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz","base":"/","source":"./","src":"./","files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"AlphaDuo","description":"My awesome app using docz","host":"localhost","port":5000,"p":3000,"separator":"-","paths":{"root":"C:\\Users\\gjuoun\\Code\\JS\\docz-test","templates":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\node_modules\\docz-core\\dist\\templates","docz":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz","cache":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\.cache","app":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\app","appPackageJson":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\package.json","gatsbyConfig":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\gatsby-config.js","gatsbyBrowser":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\gatsby-browser.js","gatsbyNode":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\gatsby-node.js","gatsbySSR":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\gatsby-ssr.js","importsJs":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\app\\imports.js","rootJs":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\app\\root.jsx","indexJs":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\app\\index.jsx","indexHtml":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\app\\index.html","db":"C:\\Users\\gjuoun\\Code\\JS\\docz-test\\.docz\\app\\db.json"},"typescrfipt":true},
    }]
